@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import './variables';

:root {
  --color-primary: #{$color_primary};
  --color-secondary: #{$color_secondary};
  --color-white: #{$color_white};
}
@media (min-width: 992px) {
}

html, body { margin: 0; padding: 0; scroll-behavior: smooth; height: 100%; min-height: 100%;}

body {
  font-size: 17px; 
  line-height: 1.15; 
  font-family: "Montserrat", sans-serif; 
  font-weight: 400; 
  color: #222;
  @media (min-width: 992px) { 
    font-size: 21px; 
  }
}

* {
  box-sizing: border-box;
}

*:focus-visible {
  outline: none !important;
}

:global {
  #root {min-height: 100%; display: flex; flex-direction: column; flex-grow: 1;}

  h1, h2, h3 {
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
    color: var(--color-primary);
  }

  h4, h5 {
    margin-top: .5rem;
    margin-bottom: 1.5rem;
  }

  h1 {font-size: 3.5rem;}
  h2 {font-size: 2.5rem;}
  h3 {font-size: 1.75rem;}
  h4 {font-size: 1.25rem;}
  h5 {font-size: 1rem;}

  p{
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  a {
    color: $color_primary;
    text-decoration: underline;
  }

  a:hover {
    color: darken($color_primary, 20%);
    text-decoration: underline;
  }

  .text-color-primary {color: var(--color-primary);}
  .text-color-secondary {color: var(--color-secondary);}

  .fs-huge {font-size: 6rem;}
  .fs-large {font-size: 3rem;}
  .fs-medium {font-size: 2rem;}
  .fs-normal {font-size: 1rem;}
  .fs-small {font-size: .875rem;}

  .container {
    display: block flow-root;
    max-width: 1476px;
    width: 100%;
    margin: 0 auto;  
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: 992px) { 
      padding-left: 32px; 
      padding-right: 32px;
    }
  
  }
}