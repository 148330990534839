.wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.svgChange {
  svg path:nth-child(5n + 1) {
    animation: 4s linear 0s infinite normal none running opacityChange;
  }
  svg path:nth-child(5n + 2) {
    animation: 2.5s linear .25s infinite normal none running opacityChange;
  }
  svg path:nth-child(5n + 3) {
    animation: 2.5s linear .5s infinite normal none running opacityChange;
  }
  svg path:nth-child(5n + 4) {
    animation: 2.5s linear .75s infinite normal none running opacityChange;
  }
  svg path:nth-child(5n) {
    animation: 2.5s linear 1s infinite normal none running opacityChange;
  }
}

@keyframes opacityChange {
  0% { opacity: 1; }  
  50% { opacity: 0; }
  100% { opacity: 1; }  
}